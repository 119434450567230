<template>
  <PageWrapper
    :active-section="SECTION_MONETIZATION"
    :active-page="PAGE_INSPIRATION_NETWORK"
    title="Inspiration Network"
    :sub-title="pageWrapperSubtitle"
    sub-title-type="iText"
    :breadcrumbs="breadcrumbs"
    page-name="Inspiration Network"
    align="left"
    width="fixed"
  >
    <smart-feed default-smart-feed-layout="table" default-entity-layout="tableRow" :pinia-store="useInspirationNetworkFeedStore">
      <template #above-content>
        <iAutoComplete
          v-model="searchQuery"
          is-async
          placeholder="Search for a brand"
          :items="searchInspirationPartnerships"
          :debounce-time="300"
          item-value-key="advertiser_name"
          item-label-key="advertiser_name"
          :loading="loadingSearchInspirationNetworkPartnerships"
          @focus="!searchQuery ? searchInspirationNetworkPartnerships() : null"
          @search="searchInspirationNetworkPartnerships"
          @enter="reloadSmartFeedStore"
          @item-selected="handleSelect"
          @update:model-value="searchAndReloadIfRequired($event)"
        />
      </template>
    </smart-feed>
  </PageWrapper>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import { usePartnershipStore } from "@/stores/partnership-store";
import PageWrapper from "@/components/PageWrapper";
import {
  PAGE_INSPIRATION_NETWORK,
  SECTION_MONETIZATION,
} from "@/constants/nav-constants";
import SmartFeed from "SmartFeed/components/SmartFeed.vue";
import { useInspirationNetworkFeedStore } from "SmartFeed/stores/feed/inspiration-network-feed-store";
import { toRaw } from "vue";
import { useGeneralStore } from "%/stores/general-store";

export default {
  name: "InspirationNetwork",
  components: {
    SmartFeed,
    PageWrapper,
  },
  props: {
    partnerCount: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      PAGE_INSPIRATION_NETWORK,
      SECTION_MONETIZATION,
      fields: [
        {
          key: "advertiser_name",
          label: "Name",
        },
        {
          key: "payout",
          label: "Payout",
        },
        {
          key: "domains",
          label: "Domains",
        },
      ],
    };
  },
  computed: {
    ...mapWritableState(useInspirationNetworkFeedStore, ["searchQuery"]),
    ...mapState(usePartnershipStore, [
      "searchInspirationPartnerships",
      "loadingSearchInspirationNetworkPartnerships",
      "orgId",
    ]),
    ...mapState(useGeneralStore, ["links"]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/monetization/org/${this.orgId}`, label: "Monetization" },
      ];
    },
    pageWrapperSubtitle() {
      const advertisers = this.partnerCount > 1 ? "advertisers" : "advertiser";
      return `${this.partnerCount} ${advertisers} in network`;
    },
  },
  methods: {
    useInspirationNetworkFeedStore,
    ...mapActions(usePartnershipStore, [
      "searchInspirationNetworkPartnerships",
    ]),
    handleSelect($event) {
      if ($event && typeof $event === "object") {
        window.location.href = `${this.links.web}/community/org/${$event.advertiser_org_id}/profile`;
      } else {
        this.reloadSmartFeedStore();
      }
    },
    searchAndReloadIfRequired(value) {
      this.searchQuery = value;
      if (!value) {
        this.reloadSmartFeedStore();
      }
    },
    reloadSmartFeedStore() {
      useInspirationNetworkFeedStore().reload({
        "searchQuery": toRaw(this.searchQuery),
      });
    },
  },
};
</script>
