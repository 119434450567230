import { defineStore } from "pinia";
import { usePaymentStore } from "%/stores/payments-store";
import { $axios } from "@bloglovin/vue-framework";
import { useGeneralStore } from "%/stores/general-store";
import { links } from "%/enums/link-enums";
import { SIGN_UP_SETTINGS_MAP } from "@/constants/sign-up-settings-map";

export const useConfigurationStore = defineStore("configuration-store", {
  state: () => ({
    $_configuration: {}, // Private state variable for configuration data
    isLoadingUpdateConfiguration: false,
    isLoadingPublishConfigToProduction: false,
    isLoadingCreateSubscriptionPlan: false,
    publicationStagingVersionId: 0,
    publicationId: 0,
  }),
  getters: {
    selectedAccessControlTypeKey() {
      const foundSetting = Object.entries(SIGN_UP_SETTINGS_MAP).find(
        ([, setting]) => {
          return (
            setting.features.canSignUp === this.canSignUp &&
                setting.features.isPrivatePublication === this.isPrivatePublication &&
                setting.features.requireAccountApproval === this.requireAccountApproval
          );
        },
      );
      return foundSetting ? foundSetting[0] : null;
    },
    selectedAccessControlTypeOption() {
      return SIGN_UP_SETTINGS_MAP[this.selectedAccessControlTypeKey];
    },
    areSubscriptionsAvailable() {
      return this.canSignUp && this.availableSubscriptionPlans.length > 0;
    },
    canCreateQuickPost() {
      return this.ugcInterface === "quickPost";
    },
    isSignupAndLoginEnabled() {
      return this.canSignUp || this.isPrivatePublication;
    },
    primaryNavigation() {
      return this.$_configuration.primaryNav;
    },
    availableSubscriptionPlans() {
      return this.$_configuration.availableSubscriptionPlans;
    },
    canVoteOnPosts() {
      return this.$_configuration.canVoteOnPosts;
    },
    canLikePosts() {
      return this.$_configuration.canLikePosts;
    },
    commentModerationStrategy() {
      return this.$_configuration.commentModerationStrategy;
    },
    commentReplyDisplayStyle() {
      return this.$_configuration.commentReplyDisplayStyle;
    },
    digitalProductsVisible() {
      return this.$_configuration.digitalProductsVisible;
    },
    commentReplyNestingLimit() {
      return this.$_configuration.commentReplyNestingLimit;
    },
    canLikeComments() {
      return this.$_configuration.canLikeComments;
    },
    isPrivatePublication() {
      return this.$_configuration.isPrivatePublication;
    },
    requireAccountApproval() {
      return this.$_configuration.requireAccountApproval;
    },
    ugcInterface() {
      return this.$_configuration.ugcInterface;
    },
    freeCommunityType() {
      return this.$_configuration.freeCommunityType;
    },
    exclusiveCommunityType() {
      return this.$_configuration.exclusiveCommunityType;
    },
    commentDefaultSortingStrategy() {
      return this.$_configuration.commentDefaultSortingStrategy;
    },
    ugcModerationStrategy() {
      return this.$_configuration.ugcModerationStrategy;
    },
    canCommentOnPosts() {
      return this.$_configuration.canCommentOnPosts;
    },
    canReplyToComments() {
      return this.$_configuration.canReplyToComments;
    },
    canVoteOnComments() {
      return this.$_configuration.canVoteOnComments;
    },
    canSignUp() {
      return this.$_configuration.canSignUp;
    },
    canUserCreatePost() {
      return this.ugcInterface !== "none";
    },
    dynamicComponents() {
      if (this.$_configuration.dynamicComponents === undefined) {
        return {};
      }
      return Object.fromEntries(
        Object.entries(this.$_configuration.dynamicComponents).filter(([, component]) => component.dynamic_component_type === "standard-component"),
      );
    },
    customDynamicComponents() {
      if (this.$_configuration.dynamicComponents === undefined) {
        return {};
      }
      return Object.fromEntries(
        Object.entries(this.$_configuration.dynamicComponents).filter(([, component]) => component.dynamic_component_type === "custom-component"),
      );
    },
    dynamicPages() {
      if (this.$_configuration.dynamicComponents === undefined) {
        return {};
      }
      return Object.fromEntries(
        Object.entries(this.$_configuration.dynamicComponents).filter(([, component]) => component.dynamic_component_type === "standard-page"),
      );
    },
    customDynamicPages() {
      if (this.$_configuration.dynamicComponents === undefined) {
        return {};
      }
      return Object.fromEntries(
        Object.entries(this.$_configuration.dynamicComponents).filter(([, component]) => component.dynamic_component_type === "custom-page"),
      );
    },
  },
  actions: {
    addDynamicComponent(componentConfigData) {
      this.$_configuration.dynamicComponents[componentConfigData.dynamic_component_id] = componentConfigData;
    },
    getDynamicComponentConfigurationByName(componentName) {
      return Object.values(this.$_configuration.dynamicComponents).find(component => component.dynamic_component_name === componentName);
    },
    setPublicationId(publicationId) {
      this.publicationId = publicationId;
    },
    setPublicationStagingVersionId(publicationStagingVersionId) {
      this.publicationStagingVersionId = publicationStagingVersionId;
    },
    updateConfiguration(newConfigData) {
      this.isLoadingUpdateConfiguration = true;
      const apiUri = useGeneralStore().getLink(links.api);

      $axios
          .post(
            `${apiUri}/publication/${this.publicationId}/version/${this.publicationStagingVersionId}/update`,
            { config_data: newConfigData },
            {
              headers: {
                Authorization: "",
              },
            },
          )
          .then(response => {
            this.$_configuration = response.data.configuration;
            this.publicationStagingVersionId = response.data.publicationVersionId;
          })
          .finally(() => {
            this.isLoadingUpdateConfiguration = false;
          });
    },
    publishConfigToProduction(memo, successCallback) {
      this.isLoadingPublishConfigToProduction = true;

      this.apiPost( `/publication/${this.publicationId}/version/${this.publicationStagingVersionId}/publish`,
        {
          memo,
        },
        data => {
          this.$_configuration = data.configuration;
          this.publicationStagingVersionId = data.publicationVersionId;
        },
        () => {

        },
        () => {
          this.isLoadingPublishConfigToProduction = false;
          if (successCallback) {
            successCallback();
          } else {
            window.location.reload();
          }
        });
    },
    createSubscriptionPlan(
      name,
      description,
      billingFrequency,
      price,
      currencyCode,
      accessRevocationGracePeriod,
      exclusivityWindow,
      successCallback = () => {
      },
    ) {
      this.isLoadingCreateSubscriptionPlan = true;
      const paymentStore = usePaymentStore();
      const generalStore = useGeneralStore();

      return $axios
          .post(
            `/subscription/publication/${this.publicationId}/version/${this.publicationStagingVersionId}`,
            {
              name,
              description,
              billing_frequency: billingFrequency,
              price,
              currency_code: currencyCode,
              access_revocation_grace_period_days: accessRevocationGracePeriod,
              exclusivity_window_days: exclusivityWindow,
            },
          )
          .then(async response => {
            const data = response.data;
            successCallback(data);
            this.$_configuration = data.configuration;

            const newPlan =
                data.activeSubscriptionPlans[data.activeSubscriptionPlans.length - 1];

            await paymentStore.createSubscriptionProductAndPrice(
              newPlan.name,
              newPlan.description,
              newPlan.price * 100,
              newPlan.currency_code,
              newPlan.billing_frequency,
              newPlan.subscription_plan_id,
              generalStore.userId,
              generalStore.orgId,
              newPlan.publication_id,
              generalStore.orgId,
              true,
            );

            return data;
          })
          .finally(() => {
            this.isLoadingCreateSubscriptionPlan = false;
          });
    },
    setConfiguration(configuration) {
      this.$_configuration = configuration;
    },
    addDynamicComponentToConfiguration(dynamicComponentId, dynamicComponent) {
      this.$_configuration.dynamicComponents[dynamicComponentId] = dynamicComponent;
    },
  },
});
