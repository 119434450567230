<template>
  <iModal
    :visible="open"
    :max-width="750"
    :title="modalTitle"
    @close="closeModal"
  >
    <template #body>
      <iColumn>
        <iRow gap="large" vertical-align="middle">
          <iImageContainer :src="imageSrc" :height="100" />
          <iUploader @upload-file="uploadAffiliateLinkCollectionCover">
            <iButton variant="secondary" size="small">
              {{ checkForSelectedImage ? "Change" : "Select" }} Cover Image
            </iButton>
          </iUploader>
        </iRow>
        <iRow>
          <iTextInput v-model="name" placeholder="Fall Outfits" label="Name" />
        </iRow>
        <iRow>
          <iTextInput v-model="category" placeholder="Clothes" label="Category" />
        </iRow>
        <iRow>
          <iTextArea v-model="description" placeholder="Ideas to post Labor Day - Thanksgiving" label="Description" />
        </iRow>
      </iColumn>
    </template>
    <template #footer>
      <iRow align="right">
        <iColumn width="hug">
          <iButton variant="secondary" @click.stop="closeModal">
            Cancel
          </iButton>
        </iColumn>
        <iColumn width="hug">
          <iButton
            :is-loading="isLoading"
            :disabled="!name"
            @click.stop="submit"
          >
            Save
          </iButton>
        </iColumn>
      </iRow>
    </template>
  </iModal>
</template>
<script>
import { mapActions, mapState } from "pinia";
import { useAffiliateLinkStore } from "@/stores/affiliate-link-store";
import { DEFAULT_COLLECTION_COVER_IMAGE } from "@/constants/affiliate-constants";

export default {
  name: "CollectionDetailsModal",
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    affiliateLinkCollection: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  emits: ["close", "save"],
  data() {
    return {
      name: "",
      category: "",
      description: "",
      coverImage: "",
      coverImageUpload: null,
      visibility: "public",
      DEFAULT_COLLECTION_COVER_IMAGE,
    };
  },
  computed: {
    ...mapState(useAffiliateLinkStore, [
      "loadingAffiliateLinkCollection",
      "loadingAffiliateLinkCollection",
      "uploadedAffiliateLinkCollectionCoverUrl",
    ]),
    isEditContext() {
      return this.affiliateLinkCollection.affiliateLinkCollectionId > 0;
    },
    modalTitle() {
      if (this.isEditContext) {
        return "Edit Collection";
      }
      return "Create Collection";
    },
    checkForSelectedImage() {
      return this.coverImage || this.uploadedAffiliateLinkCollectionCoverUrl;
    },
    imageSrc() {
      if (this.uploadedAffiliateLinkCollectionCoverUrl) {
        return this.uploadedAffiliateLinkCollectionCoverUrl;
      } else if (this.coverImage) {
        return this.coverImage;
      } else {
        return this.DEFAULT_COLLECTION_COVER_IMAGE;
      }
    },
    isLoading() {
      return this.loadingAffiliateLinkCollection || this.loadingAffiliateLinkCollection;
    },
  },
  created() {
    if (this.isEditContext) {
      this.name = this.affiliateLinkCollection.name;
      this.category = this.affiliateLinkCollection.category;
      this.description = this.affiliateLinkCollection.description;
      this.coverImage = this.affiliateLinkCollection.coverImage;
    }
  },
  methods: {
    ...mapActions(useAffiliateLinkStore, [
      "saveCollectionDetails",
      "createAffiliateLinkCollection",
      "editAffiliateLinkCollection",
      "uploadAffiliateLinkCollectionCover",
    ]),
    closeModal() {
      this.$emit("close");
    },
    submit() {
      if (!this.name) {
        return;
      }
      if (this.isEditContext) {
        this.editAffiliateLinkCollection(
          this.affiliateLinkCollection.affiliateLinkCollectionId,
          this.name,
          this.category,
          this.description,
          this.imageSrc,
        ).then(() => {
          this.closeModal();
        });
      } else {
        this.createAffiliateLinkCollection(
          this.name,
          0,
          this.category,
          this.description,
          this.imageSrc,
        ).then(() => {
          this.closeModal();
        });
      }
    },
  },
};
</script>
