// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.manage-users[data-v-2ff0755a] {
  padding-top: var(--2ff0755a-\\\$getStyles\\.paddingTop);
  padding-bottom: var(--2ff0755a-\\\$getStyles\\.paddingBottom);
  padding-left: var(--2ff0755a-\\\$getStyles\\.paddingLeft);
  padding-right: var(--2ff0755a-\\\$getStyles\\.paddingRight);
}`, "",{"version":3,"sources":["webpack://./src/SharedWeb/scripts/components/community/chatrooms/slack-style/ManageUsers.vue","webpack://./ManageUsers.vue"],"names":[],"mappings":"AAEA;EACE,oDAAA;EACA,0DAAA;EACA,sDAAA;EACA,wDAAA;ACDF","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.manage-users{\n  padding-top: v-bind(\"$getStyles.paddingTop\");\n  padding-bottom: v-bind(\"$getStyles.paddingBottom\");\n  padding-left: v-bind(\"$getStyles.paddingLeft\");\n  padding-right: v-bind(\"$getStyles.paddingRight\");\n}\n",".manage-users {\n  padding-top: v-bind(\"$getStyles.paddingTop\");\n  padding-bottom: v-bind(\"$getStyles.paddingBottom\");\n  padding-left: v-bind(\"$getStyles.paddingLeft\");\n  padding-right: v-bind(\"$getStyles.paddingRight\");\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
