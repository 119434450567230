// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-191b3bee] .user-avatar img {
  border-radius: var(--191b3bee-\\\$getStyles\\.borderRadius);
}
.header-wrapper[data-v-191b3bee] {
  padding-left: var(--191b3bee-\\\$getStyles\\.avatarOffset);
}
.message-avatar[data-v-191b3bee] {
  position: relative;
  top: 4px;
}
.timestamp[data-v-191b3bee], .timestamp[data-v-191b3bee]:hover {
  color: var(--191b3bee-\\\$getStyles\\.timestampColor);
}`, "",{"version":3,"sources":["webpack://./src/SharedWeb/scripts/components/community/chatrooms/slack-style/ConversationHeader.vue","webpack://./ConversationHeader.vue"],"names":[],"mappings":"AAEA;EACE,wDAAA;ACDF;ADIA;EACE,uDAAA;ACDF;ADIA;EACE,kBAAA;EACA,QAAA;ACDF;ADGA;EACE,kDAAA;ACAF","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n:deep(.user-avatar img) {\n  border-radius: v-bind(\"$getStyles.borderRadius\");\n}\n\n.header-wrapper {\n  padding-left: v-bind(\"$getStyles.avatarOffset\");\n}\n\n.message-avatar {\n  position: relative;\n  top: 4px;\n}\n.timestamp, .timestamp:hover {\n  color: v-bind(\"$getStyles.timestampColor\");\n}\n",":deep(.user-avatar img) {\n  border-radius: v-bind(\"$getStyles.borderRadius\");\n}\n\n.header-wrapper {\n  padding-left: v-bind(\"$getStyles.avatarOffset\");\n}\n\n.message-avatar {\n  position: relative;\n  top: 4px;\n}\n\n.timestamp, .timestamp:hover {\n  color: v-bind(\"$getStyles.timestampColor\");\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
