<template>
  <div v-if="smartFeedLayout !== 'grid'" :class="['smart-feed-list-container', smartFeedLayout]">
    <div v-for="index of defaultNumberOfSmartFeedItems" :key="index" :class="['smart-feed-item', entityLayout]" />
  </div>
  <template v-else>
    <div :class="['smart-feed-list-container', smartFeedLayout]">
      <div v-for="index of defaultNumberOfSmartFeedItems" :key="index" :class="['smart-feed-item', entityLayout]" />
    </div>
    <div :class="['smart-feed-list-container', smartFeedLayout]">
      <div v-for="index of defaultNumberOfSmartFeedItems" :key="index" :class="['smart-feed-item', entityLayout]" />
    </div>
    <div :class="['smart-feed-list-container', smartFeedLayout]">
      <div v-for="index of defaultNumberOfSmartFeedItems" :key="index" :class="['smart-feed-item', entityLayout]" />
    </div>
  </template>
</template>

<script>
import { Enum } from "@bloglovin/vue-component-library";
import BaseSmartFeedEngine from "SmartFeed/smart-feed-engine";

export default {
  name: "SmartFeedPlaceholder",
  props: {
    entityLayout: {
      type: Enum,
      required: true,
      options: [...BaseSmartFeedEngine.VALID_ENTITY_LAYOUTS],
      // @todo add a validator to ensure that default smart feed layout table has default entity layout table and continues to validate.
    },
    smartFeedLayout: {
      type: Enum,
      options: [...BaseSmartFeedEngine.VALID_SMART_FEED_LAYOUTS],
      required: true,
    },
  },
  computed: {
    defaultNumberOfSmartFeedItems() {
      switch (this.smartFeedLayout) {
        case "grid":
          return 4;

        case "carousel":
          return 12;

        case "list":
          return 6;
      }

      return 12 ;
    },
  },
};
</script>
<style  lang="scss" scoped>
.smart-feed-list-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1em;
  flex-wrap: wrap;
  & > .smart-feed-item {
    display: flex;
    height: 100px;
    width: 200px;
    background-color: lightgray;
    border-radius: 1em;
  }
}

.grid {
  flex-wrap: nowrap;
  margin-bottom: 1em;
  & > .smart-feed-item {
    height: 150px;
    width: 100%;
  }
}

.list {
  width: 100%;
  flex-direction: column;
  overflow-y: auto;
  max-height: 1000px;

  & > .smart-feed-item {
    height: 80px;
    width: 100%;
  }
}

.carousel {
  width: 100%;
  flex-wrap: nowrap;
  overflow-x: auto;

  & > .smart-feed-item {
    height: 250px;
    flex-shrink: 0;
  }
}
</style>
