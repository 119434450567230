import { defineStore } from "pinia";
import { useGeneralStore } from "%/stores/general-store";
import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import {
  useMyPublicationCollaboratorsFeedStore,
} from "SmartFeed/stores/feed/use-my-publication-collaborators-feed-store";
import { useCollaboratorInvitationFeedStore } from "SmartFeed/stores/feed/collaborator-invitation-feed-store";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";
import { useCollaboratingPublicationFeedStore } from "SmartFeed/stores/feed/collaborating-publication-feed-store";

export const useCollaboratorStore = defineStore("collaborator-store", {
  state: () => ({
    selectedCollaboratorForRoleChange: null,
    selectedInvitationReview: null,
    collaboratorApplication: null,
    isLoadingInvitations: false,
    isLoadingApplications: false,
    isCreatingOnPlatformInvitation: false,
    isCreatingOffPlatformInvitation: false,
    isCreatingApplication: false,
    isAcceptingInvitation: false,
    isDenyingInvitation: false,
    isRevokingInvitation: false,
    isAcceptingApplication: false,
    isDenyingApplication: false,
    isRevokingApplication: false,
    isResendingInvitation: false,
    isEnablingCollaborator: false,
    isDisablingCollaborator: false,
    isInvitationReviewModalVisible: false,
    isChangeRoleModalVisible: false,
    isCounterOfferModalVisible: false,
    isChangingRole: false,
    isLoadingPageOfApplicants: false,
    isLoadingApplication: false,
    error: null,
  }),

  getters: {
    orgId() {
      return useGeneralStore().orgId;
    },
  },

  actions: {
    createOnPlatformInvitation(
      publicationId,
      orgId,
      ownerOrgId,
      name,
      role,
      onSuccessCallBack = null,
      onErrorCallback = null,
    ) {
      if (this.isCreatingOnPlatformInvitation) {
        return;
      }

      this.isCreatingOnPlatformInvitation = true;
      this.error = null;

      return this.apiPost(
        "/collaborator/invitation/on-platform",
        {
          publication_id: publicationId,
          org_id: orgId,
          owner_org_id: ownerOrgId,
          name,
          role,
        },
        () => {
          useMyPublicationCollaboratorsFeedStore().reload();
          if (onSuccessCallBack) {
            onSuccessCallBack();
          }
        },
        error => {
          if (onErrorCallback) {
            onErrorCallback();
          }
          this.error =
                error.response?.data?.message ||
                "Failed to create on-platform collaborator invitation";
        },
        () => {
          this.isCreatingOnPlatformInvitation = false;
        },
      );
    },
    createOffPlatformInvitation(
      publicationId,
      ownerOrgId,
      email,
      name,
      role,
      onSuccessCallBack = null,
      onErrorCallback = null,
    ) {
      if (this.isCreatingOffPlatformInvitation) {
        return;
      }

      this.isCreatingOffPlatformInvitation = true;
      this.error = null;

      return this.apiPost(
        "/collaborator/invitation/off-platform",
        {
          publication_id: publicationId,
          owner_org_id: ownerOrgId,
          email,
          name,
          role,
        },
        () => {
          useMyPublicationCollaboratorsFeedStore().reload();
          if (onSuccessCallBack) {
            onSuccessCallBack();
          }
        },
        error => {
          this.error =
                error.response?.data?.message ||
                "Failed to create off-platform collaborator invitation";
          if (onErrorCallback) {
            onErrorCallback();
          }
        },
        () => {
          this.isCreatingOffPlatformInvitation = false;
        },
      );
    },
    createApplication(
      publicationId,
      orgId,
      ownerOrgId,
      email,
      name,
      role,
    ) {
      if (this.isCreatingApplication) {
        return;
      }

      this.isCreatingApplication = true;
      this.error = null;

      return this.apiPost(
        "/collaborator/application",
        {
          publication_id: publicationId,
          org_id: orgId,
          owner_org_id: ownerOrgId,
          email,
          name,
          role,
        },
        response => {
          useCreatorPublicationStore().setCollaboratorRelationship(response.userRelationship);
        },
        error => {
          this.error =
                error.response?.data?.message ||
                "Failed to create collaborator application";
        },
        () => {
          this.isCreatingApplication = false;
        },
      );
    },
    acceptInvitation(publicationId, orgId ) {
      if (this.isAcceptingInvitation) {
        return;
      }
      this.isAcceptingInvitation = true;
      this.error = null;

      return this.apiPost(
        `/collaborator/${publicationId}/${orgId}/invitation/accept`,
        {},
        () => {
          useCollaboratorInvitationFeedStore().reload();
          useCollaboratingPublicationFeedStore().reload();
          this.closeInvitationReviewModal();
        },
        error => {
          this.error =
                error.response?.data?.message ||
                "Failed to accept collaborator invitation";
        },
        () => {
          this.isAcceptingInvitation = false;
        },
      );
    },
    denyInvitation(publicationId, orgId ) {
      if (this.isDenyingInvitation) {
        return;
      }
      this.isDenyingInvitation = true;
      this.error = null;

      return this.apiPost(
        `/collaborator/${publicationId}/${orgId}/invitation/deny`,
        {},
        () => {
          useCollaboratorInvitationFeedStore().reload();
          this.closeInvitationReviewModal();
        },
        error => {
          this.error =
                error.response?.data?.message ||
                "Failed to deny collaborator invitation";
        },
        () => {
          this.isDenyingInvitation = false;
        },
      );
    },
    revokeInvitation(publicationId, orgId ) {
      if (this.isRevokingInvitation) {
        return;
      }
      this.isRevokingInvitation = true;
      this.error = null;

      return this.apiPost(
        `/collaborator/${publicationId}/${orgId}/invitation/revoke`,
        {},
        response => {
          new SmartFeedEngine().addEntities(response.entities);
        },
        error => {
          this.error =
                error.response?.data?.message ||
                "Failed to revoke collaborator invitation";
        },
        () => {
          this.isRevokingInvitation = false;
        },
      );
    },
    acceptApplication(publicationId, orgId ) {
      if (this.isAcceptingApplication) {
        return;
      }
      this.isAcceptingApplication = true;
      this.error = null;

      return this.apiPost(
        `/collaborator/${publicationId}/${orgId}/application/accept`,
        {},
        response => {
          new SmartFeedEngine().addEntities(response.entities);
        },
        error => {
          this.error =
                error.response?.data?.message ||
                "Failed to accept collaborator application";
        },
        () => {
          this.isAcceptingApplication = false;
        },
      );
    },
    denyApplication(publicationId, orgId ) {
      if (this.isDenyingApplication) {
        return;
      }
      this.isDenyingApplication = true;
      this.error = null;

      return this.apiPost(
        `/collaborator/${publicationId}/${orgId}/application/deny`,
        {},
        response => {
          new SmartFeedEngine().addEntities(response.entities);
        },
        error => {
          this.error =
                error.response?.data?.message ||
                "Failed to deny collaborator application";
        },
        () => {
          this.isDenyingApplication = false;
        },
      );
    },
    revokeApplication(publicationId, orgId ) {
      if (this.isRevokingApplication) {
        return;
      }
      this.isRevokingApplication = true;
      this.error = null;

      return this.apiPost(
        `/collaborator/${publicationId}/${orgId}/application/revoke`,
        {},
        response => {
          new SmartFeedEngine().addEntities(response.entities);
        },
        error => {
          this.error =
                error.response?.data?.message ||
                "Failed to revoke collaborator application";
        },
        () => {
          this.isRevokingApplication = false;
        },
      );
    },
    resendApplicantInvitation(publicationId, orgId ) {
      if (this.isResendingInvitation) {
        return;
      }
      this.isResendingInvitation = true;
      this.error = null;

      return this.apiPost(
        `/collaborator/${publicationId}/${orgId}/invitation/resend`,
        {},
        response => {
          if (response && response.entities) {
            new SmartFeedEngine().addEntities(response.entities);
          }
        },
        error => {
          this.error =
                error.response?.data?.message ||
                "Failed to resend collaborator invitation";
        },
        () => {
          this.isResendingInvitation = false;
        },
      );
    },
    enableCollaborator(publicationId, orgId ) {
      if (this.isEnablingCollaborator) {
        return;
      }
      this.isEnablingCollaborator = true;
      this.error = null;

      return this.apiPost(
        `/collaborator/${publicationId}/${orgId}/enable`,
        {},
        response => {
          new SmartFeedEngine().addEntities(response.entities);
        },
        error => {
          this.error =
                error.response?.data?.message ||
                "Failed to enable collaborator";
        },
        () => {
          this.isEnablingCollaborator = false;
        },
      );
    },
    disableCollaborator(publicationId, orgId ) {
      if (this.isDisablingCollaborator) {
        return;
      }
      this.isDisablingCollaborator = true;
      this.error = null;

      return this.apiPost(
        `/collaborator/${publicationId}/${orgId}/disable`,
        {},
        response => {
          new SmartFeedEngine().addEntities(response.entities);
        },
        error => {
          this.error =
                error.response?.data?.message ||
                "Failed to disable collaborator";
        },
        () => {
          this.isDisablingCollaborator = false;
        },
      );
    },
    changeRole(applicantId, publicationId, role ) {
      if (this.isChangingRole) {
        return;
      }
      this.isChangingRole = true;
      this.error = null;

      return this.apiPost(
        `/collaborator/${applicantId}/${publicationId}/role`,
        { role },
        response => {
          new SmartFeedEngine().addEntities(response.entities);
          this.closeChangeRoleModal();
        },
        error => {
          this.error =
                error.response?.data?.message ||
                "Failed to change collaborator role";
        },
        () => {
          this.isChangingRole = false;
        },
      );
    },
    getApplication({ publicationId, orgId }) {
      if (this.isLoadingApplication) {
        return;
      }
      this.isLoadingApplication = true;
      this.error = null;

      return this.apiGet(
        `/collaborator/${publicationId}/${orgId}/application`,
        {},
        data => {
          this.collaboratorApplication = data;
        },
        error => {
          this.error =
                error.response?.data?.message ||
                "Failed to load collaborator application";
        },
        () => {
          this.isLoadingApplication = false;
        },
      );
    },
    openInvitationReviewModal(invitation) {
      this.selectedInvitationReview = invitation;
      this.isInvitationReviewModalVisible = true;
    },
    closeInvitationReviewModal() {
      this.selectedInvitationReview = null;
      this.isInvitationReviewModalVisible = false;
    },
    openCounterOfferModal() {
      this.isCounterOfferModalVisible = true;
    },
    closeCounterOfferModal() {
      this.isCounterOfferModalVisible = false;
    },
    openChangeRoleModal(entity) {
      this.error = null;
      this.isChangeRoleModalVisible = true;
      this.selectedCollaboratorForRoleChange = entity;
    },
    closeChangeRoleModal() {
      this.isChangeRoleModalVisible = false;
      this.selectedCollaboratorForRoleChange = null;
      this.error = null;
    },
  },
});
