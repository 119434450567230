import { useGeneralStore } from "%/stores/general-store";
import { usePublicationStore } from "@/stores/publication-store";
import { defineStore } from "pinia";
import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import { useMyPublicationDigitalProductFeedStore } from "SmartFeed/stores/feed/my-publication-digital-product-feed-store";
import { useMySubscriptionPlanFeedStore } from "SmartFeed/stores/feed/my-subscription-plan-feed-store";

export const usePublicationDigitalProductsStore = defineStore(
  "publication-digital-products-store",
  {
    state: () => ({
      publicationDigitalProducts: [],
      publicationDigitalProductsSearchResults: [],
      publicationDigitalProductLastId: 0,
      publicationDigitalProductLastIdSearch: 0,
      isAddSubscriptionProductModelOpen: false,
      isEditSubscriptionProductModelOpen: false,
      activeSubscriptionPlanId: null,
      activeSubscriptionProductId: null,
      isCreatingDigitalProduct: false,
      isCreatingSubscriptionProduct: false,
      isUpdatingSubscriptionProduct: false,
      isCreateDigitalProductModalOpen: false,
      isAutoCompleteLoading: false,
      error: null,
    }),

    getters: {
      orgId() {
        return useGeneralStore().orgId;
      },
      publicationId() {
        return usePublicationStore().publicationId;
      },
    },

    actions: {
      async createDigitalProduct({
        type,
        name,
        description,
        startAvailableForPurchaseTimestamp = 0,
        endAvailableForPurchaseTimestamp = 0,
        fileUrl = null,
        capability = null,
        accessType = null,
        file = null,
      }) {
        this.isCreatingDigitalProduct = true;
        this.error = null;

        const formData = new FormData();
        formData.append("type", type);
        formData.append("name", name);
        formData.append("description", description);
        formData.append("creator_org_id", this.orgId);
        formData.append("start_available_for_purchase_timestamp", startAvailableForPurchaseTimestamp);
        formData.append("end_available_for_purchase_timestamp", endAvailableForPurchaseTimestamp);

        if (typeof fileUrl === "string") {
          formData.append("file_url", fileUrl);
        }
        if (capability) {
          formData.append("capability", capability);
        }
        if (accessType) {
          formData.append("access_type", accessType);
        }
        if (file instanceof File) {
          formData.append("file", file);
        }
        await this.apiPost(
          `/publication-digital-products/publication/${this.publicationId}/create`,
          formData,
          () => {
            this.isCreatingDigitalProduct = false;
            useMyPublicationDigitalProductFeedStore().reload();
            this.isCreateDigitalProductModalOpen = false;
          },
          error => {
            this.isCreatingDigitalProduct = false;
            this.error = error.response?.data?.message || "Failed to create digital product";
          },
        );
      },

      digitalProductSearch(publicationId, name) {
        if (this.isAutoCompleteLoading) {
          return;
        }

        this.isAutoCompleteLoading = true;
        this.error = null;

        return this.apiGet(
          `/publication-digital-products/publication/${publicationId}/search`,
          {
            name,
          },
          response => {
            this.isAutoCompleteLoading = false;

            this.publicationDigitalProductsSearchResults =
              response.digital_products;
          },
          () => {
            this.isAutoCompleteLoading = false;
            this.error = "Failed to create subscription product";
          },
        );
      },

      createSubscriptionProduct(
        subscriptionPlanId,
        digitalProductId,
        displayText,
        status = "active",
      ) {
        if (this.isCreatingSubscriptionProduct) {
          return;
        }

        this.isCreatingSubscriptionProduct = true;
        this.error = null;

        return this.apiPost(
          "/publication-digital-products/subscription-product/create",
          {
            subscription_plan_id: subscriptionPlanId,
            digital_product_id: digitalProductId,
            display_text: displayText,
            status,
          },
          () => {
            this.isCreatingSubscriptionProduct = false;
            useMySubscriptionPlanFeedStore().reload();
            this.closeAddSubscriptionProductModal();
          },
          () => {
            this.isCreatingSubscriptionProduct = false;
            this.error = "Failed to create subscription product";
          },
        );
      },

      openCreateDigitalProductModal() {
        return this.isCreateDigitalProductModalOpen = true;
      },

      closeCreateDigitalProductModal() {
        return this.isCreateDigitalProductModalOpen = false;
      },

      openAddSubscriptionProductModal(subscriptionPlanId) {
        this.isAddSubscriptionProductModelOpen = true;
        this.activeSubscriptionPlanId = subscriptionPlanId;
      },

      closeAddSubscriptionProductModal() {
        this.isAddSubscriptionProductModelOpen = false;
        this.activeSubscriptionPlanId = null;
      },

      openEditSubscriptionProductModal(subscriptionPlanId, subscriptionProductId) {
        this.isEditSubscriptionProductModelOpen = true;
        this.activeSubscriptionProductId = subscriptionProductId;
        this.activeSubscriptionPlanId = subscriptionPlanId;
      },

      closeEditSubscriptionProductModal() {
        this.isEditSubscriptionProductModelOpen = false;
        this.activeSubscriptionPlanId = null;
        this.activeSubscriptionProductId = null;
      },

      updateSubscriptionProduct(subscriptionProductId, displayText, status, subscriptionPlanId) {
        if (this.isUpdatingSubscriptionProduct) {
          return;
        }

        this.isUpdatingSubscriptionProduct = true;
        this.error = null;

        return this.apiPost(
          `/publication-digital-products/subscription-product/${subscriptionProductId}/update`,
          {
            display_text: displayText,
            status,
            subscription_plan_id: subscriptionPlanId,
          },
          response => {
            this.isUpdatingSubscriptionProduct = false;
            new SmartFeedEngine().addEntities(response.entities);
            this.closeEditSubscriptionProductModal();
          },
          () => {
            this.isUpdatingSubscriptionProduct = false;
            this.error = "Failed to update subscription product";
          },
        );
      },

      disableSubscriptionProduct(subscriptionPlanId, subscriptionProductId) {
        if (this.isUpdatingSubscriptionProduct) {
          return;
        }

        this.isUpdatingSubscriptionProduct = true;
        this.error = null;

        return this.apiPost(
          `/publication-digital-products/subscription-product/${subscriptionProductId}/disable`,
          {
            subscription_plan_id: subscriptionPlanId,
          },
          response => {
            this.isUpdatingSubscriptionProduct = false;
            new SmartFeedEngine().addEntities(response.entities);
          },
          () => {
            this.isUpdatingSubscriptionProduct = false;
            this.error = "Failed to disable subscription product";
          },
        );
      },

      setActiveSubscriptionPlanId(subscriptionPlanId) {
        this.activeSubscriptionPlanId = subscriptionPlanId;
      },
    },
  },
);
