<template>
  <iColumn
    height="fill"
    gap="none"
    wrap="nowrap"
    class="chatroom-thread-container"
  >
    <iRow
      width="fill"
      align="between"
      vertical-align="middle"
      class="chatroom-header"
    >
      <iRow width="hug" vertical-align="middle">
        <iIcon :icon="channelVisibility === 'invite_only' ? 'lock' : 'hashtag'" size="standard" />
        <iHeading> {{ channelName }} </iHeading>
      </iRow>
      <iRow width="hug" vertical-align="middle">
        <iChip
          :show-icon="false"
          size="small"
          variant="secondary"
          class="member-count"
          @click="openChannelMembersModal"
        >
          <iText>{{ memberCountText }}</iText>
        </iChip>
        <iDropdownMenu
          :items="channelDropdownItems"
          :show-dropdown="isChannelDropdownOpen"
          position="right"
          @click="handleChannelDropdownAction"
          @close="closeChannelDropdown"
        >
          <template #trigger>
            <iRow vertical-align="middle" wrap="nowrap">
              <iIcon icon="dots-vertical" @click="isChannelDropdownOpen = !isChannelDropdownOpen" />
            </iRow>
          </template>
        </iDropdownMenu>
      </iRow>
    </iRow>
    <iRow height="fill" overflow="scroll">
      <ChatroomThread
        :context="context"
        :entity-id="entityId"
        :conversation-id="conversationId"
        :description="description"
        :channel-name="channelName"
        :channel-href="channelHref"
        :visibility="channelVisibility"
      />
    </iRow>
    <iRow v-if="context !== 'channel' || isChannelUser" height="hug">
      <NewChatroomMessage
        :context="context"
        :entity-id="entityId"
        :conversation-id="conversationId"
      />
    </iRow>
    <iColumn
      v-else
      width="fill"
      wrap="nowrap"
      vertical-align="middle"
      align="center"
    >
      <iButton variant="text" @click="openChannelDetailsModal">
        <iRow width="hug" gap="extraSmall" vertical-align="middle">
          <iIcon :icon="channelVisibility === 'invite_only' ? 'lock' : 'hashtag'" size="small" />
          <iTitle>{{ channelName }}</iTitle>
        </iRow>
      </iButton>
      <iRow width="hug">
        <iButton variant="secondary" @click="openChannelDetailsModal">
          Details
        </iButton>
        <iButton :is-loading="isLoadingJoinChannel" @click="joinChannel">
          Join Channel
        </iButton>
      </iRow>
    </iColumn>
  </iColumn>
  <iModal
    :visible="showChannelModal"
    :max-width="600"
    :show-primary-action-button="false"
    secondary-action-text="close"
    :title="modalTitle"
    @close="closeUsersModal"
    @click:secondary="closeUsersModal"
  >
    <iTabs v-model="activeTab" :tabs="tabs">
      <template #tab(members)>
        <ManageUsers />
      </template>
      <template #tab(details)>
        <iColumn>
          <iRow width="hug" vertical-align="middle">
            <iIcon :icon="channelVisibility === 'invite_only' ? 'lock' : 'hashtag'" size="standard" />
            <iHeading> {{ channelName }} </iHeading>
          </iRow>
          <iText variant="subtle">
            {{ description }}
          </iText>
          <iRow>
            <iButton
              v-if="isChannelUser"
              size="small"
              variant="secondary"
              @click="leaveChannel"
            >
              Leave
            </iButton>
            <iButton v-else size="small" @click="joinChannel">
              Join
            </iButton>
          </iRow>
        </iColumn>
      </template>
    </iTabs>
  </iModal>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useConversationStore } from "%/stores/conversation-store";
import NewChatroomMessage from "%/components/community/chatrooms/slack-style/NewChatroomMessage";
import ChatroomThread from "%/components/community/chatrooms/slack-style/ChatroomThread";
import { useChannelStore } from "%/stores/channel-store";
import ManageUsers from "%/components/community/chatrooms/slack-style/ManageUsers.vue";
import { MenuConstants } from "@bloglovin/vue-component-library";

export default {
  name: "Chatroom",
  components: {
    ChatroomThread,
    NewChatroomMessage,
    ManageUsers,
  },
  props: {
    context: {
      type: String,
      required: true,
    },
    entityId: {
      type: Number,
      required: true,
    },
    conversationId: {
      type: Number,
      required: false,
      default: 0,
    },
    channelName: {
      type: String,
      required: false,
      default: "",
    },
    description: {
      type: String,
      required: false,
      default: "",
    },
    channelHref: {
      type: String,
      required: false,
      default: "#",
    },
    userCount: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      showChannelModal: false,
      isChannelDropdownOpen: false,
      activeTab: "members",
      channelDropdownItems: [
        {
          name: "Details",
          label: "Details",
          actionType: MenuConstants.ACTION_TYPE_BUTTON_CLICK,
          actionValue: "openChannelDetailsModal",
        },
        {
          name: "Members",
          label: "Members",
          actionType: MenuConstants.ACTION_TYPE_BUTTON_CLICK,
          actionValue: "openChannelMembersModal",
        },
        {
          name: "Settings",
          label: "Settings",
          actionType: MenuConstants.ACTION_TYPE_BUTTON_CLICK,
          actionValue: "openChannelSettingsModal",
        },
      ],
      tabs: [
        { id: "details", label: "Details" },
        { id: "members", label: "Members" },
        { id: "settings", label: "Settings" },
      ],
    };
  },
  computed: {
    ...mapState(useConversationStore, ["conversation"]),
    ...mapState(useChannelStore, ["channelId", "isChannelUser", "isLoadingJoinChannel", "channelVisibility"]),
    memberCountText() {
      return `${ this.userCount } ${this.userCount == 1 ? "member" : "members" }`;
    },
    modalTitle() {
      return this.tabs[this.tabs.findIndex(tab => tab.id === this.activeTab)].label;
    },
  },

  methods: {
    ...mapActions(useChannelStore, ["joinChannel", "leaveChannel"]),
    openChannelMembersModal() {
      this.showChannelModal = true;
      this.activeTab = "members";
    },
    openChannelDetailsModal() {
      this.showChannelModal = true;
      this.activeTab = "details";
    },
    openChannelSettingsModal() {
      this.showChannelModal = true;
      this.activeTab = "settings";
    },
    closeUsersModal() {
      this.showChannelModal = false;
    },
    handleChannelDropdownAction(action) {
      this[action]();
      this.isChannelDropdownOpen = false;
    },
    closeChannelDropdown() {
      this.isChannelDropdownOpen = false;
    },
  },
  styleGuide: () => ({
    borderTopWidth: { "size.border": "thin" },
    borderTopColor: { "color.border": "standard" },
    chatroomHeaderBorderBottomSize: { "size.border": "thin" },
    chatroomHeaderBorderBottomColor: { "color.border": "standard" },
    chatroomHeaderPaddingTop: { "size.spacing": "none" },
    chatroomHeaderPaddingBottom: { "size.spacing": "standard" },
    chatroomHeaderPaddingLeft: { "size.spacing": "none" },
    chatroomHeaderPaddingRight: { "size.spacing": "none" },
  }),
};
</script>

<style scoped lang="scss">
  .chatroom {
    width: 100%;
  }
  .member-count {
    cursor: pointer;
  }
  .chatroom-header {
    padding-top : v-bind("$getStyles.chatroomHeaderPaddingTop");
    padding-bottom : v-bind("$getStyles.chatroomHeaderPaddingBottom");
    padding-left : v-bind("$getStyles.chatroomHeaderPaddingLeft");
    padding-right : v-bind("$getStyles.chatroomHeaderPaddingRight");
    border-bottom: v-bind("$getStyles.chatroomHeaderBorderBottomSize") solid v-bind("$getStyles.chatroomHeaderBorderBottomColor") !important;
  }
</style>
