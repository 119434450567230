<template>
  <iRow class="auth-form" align="center">
    <component :is="currentUserFlowStep" />
  </iRow>
</template>

<script>
import { mapState, mapWritableState } from "pinia";
import { useUserStore } from "@/stores/user-store";
import EmailForm from "@/components/auth/EmailForm";
import PasswordForm from "@/components/auth/PasswordForm";
import ForgotPasswordForm from "@/components/auth/ForgotPasswordForm";
import InvitedPasswordResetForm from "@/components/auth/InvitedPasswordResetForm";
import SignUpForm from "@/components/auth/SignUpForm";
import AcceptInvitationPasswordForm from "@/components/auth/AcceptInvitationPasswordForm";
import { PASSWORD_FORM, SIGN_UP_FORM, ACCEPT_INVITATION_PASSWORD_FORM } from "@/constants/login-flow-constants";
import PageWrapper from "@/components/PageWrapper.vue";

export default {
  name: "Auth",
  components: {
    PageWrapper,
    SignUpForm,
    EmailForm,
    PasswordForm,
    ForgotPasswordForm,
    InvitedPasswordResetForm,
    AcceptInvitationPasswordForm,
  },
  computed: {
    ...mapWritableState(useUserStore, [
      "userAuthEmail",
      "userFirstName",
      "userLastName",
      "userFlowSteps",
      "invitingOrgId",
      "invitationToken",
      "invitedUserId",
      "invitedPublicationId",
      "invitedPublicationName",
      "redirect",
    ]),
    ...mapState(useUserStore, ["currentUserFlowStep"]),
  },
  beforeMount() {
    const urlParams = new URLSearchParams(window.location.search);
    this.invitationToken = urlParams.get("token");
    this.invitingOrgId = Number(urlParams.get("inviting_org_id"));
    this.invitedUserId = Number(urlParams.get("user_id"));
    this.invitedPublicationId = Number(urlParams.get("publication_id"));
    this.invitedPublicationName = urlParams.get("publication_name");
    if (urlParams.get("redirect")) {
      this.redirect = urlParams.get("redirect");
    }
    const email = urlParams.get("email");
    const exists = urlParams.get("exists");
    const invitation = urlParams.get("invitation");
    if (!email) {
      return;
    }

    window.history.replaceState({}, document.title, window.location.pathname);

    this.userAuthEmail = email;
    this.userFirstName = urlParams.get("first_name");
    this.userLastName = urlParams.get("last_name");

    if (exists === "true") {
      this.userFlowSteps.push(PASSWORD_FORM);
    } else if (invitation === "true") {
      this.userFlowSteps.push(ACCEPT_INVITATION_PASSWORD_FORM);
    } else {
      this.userFlowSteps.push(SIGN_UP_FORM);
    }
  },
};
</script>


<style lang="scss" scoped>
.auth-form {
  margin: 0 auto;
  padding: 0 10px 10px 10px;

  position: sticky;
  background-color: white;
  top: 0;
  z-index: 1;
  div {
    width: 600px;
    margin: 0 auto;
  }
}
</style>
