<template>
  <PageWrapper
    :action-button-href="`/monetization/org/${orgId}/links/collections`"
    :active-page="collectionName"
    :active-section="SECTION_MONETIZATION"
    :breadcrumbs="breadcrumbs"
    :page-name="collectionName"
    :title="`Collection - ${collectionName}`"
    action-button-text="View Collections"
  >
    <iRow :width="2700" overflow="scroll">
      <SmartFeed
        :pinia-store="useAffiliateLinkCollectionFeedStore"
        default-smart-feed-layout="table"
        default-entity-layout="tableRow"
      >
        <template #empty-state>
          <iRow vertical-align="middle" class="empty-state">
            <iIcon icon="hyperlink" size="large" />
            <iText>No Affiliate Links yet.</iText>
          </iRow>
        </template>
      </SmartFeed>
    </iRow>

    <iModal
      :show-footer="false"
      :visible="isAddToCollectionModalOpen"
      :max-width="550"
      title="Add Link to Collection"
      @close="closeAddToCollectionModal"
    >
      <template #body>
        <SelectAffiliateCollection
          :affiliate-link-id="affiliateLink.publisherAffiliateLinkId"
          @select="selectCollection"
        />
      </template>
    </iModal>
  </PageWrapper>
</template>

<script>
import { mapState, mapWritableState } from "pinia";
import { useAffiliateLinkStore } from "@/stores/affiliate-link-store";
import { SECTION_MONETIZATION, PAGE_LINKS_COLLECTIONS } from "@/constants/nav-constants";
import PageWrapper from "@/components/PageWrapper";
import SelectAffiliateCollection from "@/components/SelectAffiliateCollection";
import SmartFeed from "SmartFeed/components/SmartFeed.vue";
import { useAffiliateLinkCollectionFeedStore } from "SmartFeed/stores/feed/affiliate-link-collection-feed-store";

export default {
  name: "CollectionsReport",
  components: {
    SmartFeed,
    PageWrapper,
    SelectAffiliateCollection,
  },
  props: {
    collectionId: {
      type: Number,
      required: true,
    },
    affiliateLinkCollection: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      SECTION_MONETIZATION,
      PAGE_LINKS_COLLECTIONS,
      reloadPageOnCollectionRemoval: false,
    };
  },
  computed: {
    ...mapState(useAffiliateLinkStore, [
      "orgId",
      "affiliateLinks",
    ]),
    ...mapWritableState(useAffiliateLinkStore, [
      "affiliateLinkCollectionId",
      "usingSmartFeed",
      "affiliateLink",
      "isAddToCollectionModalOpen",
      "affiliateDropdownId",
    ]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/monetization/org/${this.orgId}`, label: "Monetization" },
        { url: `/monetization/org/${this.orgId}/links`, label: "My Links" },
        { url: `/monetization/org/${this.orgId}/links/collections`, label: "Collections" },
      ];
    },
    collectionName() {
      return this.affiliateLinkCollection.name;
    },
  },
  async created() {
    this.affiliateLinkCollectionId = this.collectionId;
    this.usingSmartFeed = true;
  },
  methods: {
    useAffiliateLinkCollectionFeedStore,
    closeAddToCollectionModal() {
      this.affiliateLink = {};
      this.isAddToCollectionModalOpen = false;
      if (this.reloadPageOnCollectionRemoval) {
        window.location.reload();
      }
    },
    selectCollection(collection) {
      this.reloadPageOnCollectionRemoval = collection.affiliate_link_collection_id === this.collectionId;
    },
  },
};
</script>
