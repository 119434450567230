// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chatroom[data-v-4ed6b10c] {
  width: 100%;
}
.member-count[data-v-4ed6b10c] {
  cursor: pointer;
}
.chatroom-header[data-v-4ed6b10c] {
  padding-top: var(--4ed6b10c-\\\$getStyles\\.chatroomHeaderPaddingTop);
  padding-bottom: var(--4ed6b10c-\\\$getStyles\\.chatroomHeaderPaddingBottom);
  padding-left: var(--4ed6b10c-\\\$getStyles\\.chatroomHeaderPaddingLeft);
  padding-right: var(--4ed6b10c-\\\$getStyles\\.chatroomHeaderPaddingRight);
  border-bottom: var(--4ed6b10c-\\\$getStyles\\.chatroomHeaderBorderBottomSize) solid var(--4ed6b10c-\\\$getStyles\\.chatroomHeaderBorderBottomColor) !important;
}`, "",{"version":3,"sources":["webpack://./src/SharedWeb/scripts/components/community/chatrooms/slack-style/Chatroom.vue","webpack://./Chatroom.vue"],"names":[],"mappings":"AAEE;EACE,WAAA;ACDJ;ADGE;EACE,eAAA;ACAJ;ADEE;EACE,kEAAA;EACA,wEAAA;EACA,oEAAA;EACA,sEAAA;EACA,wJAAA;ACCJ","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n  .chatroom {\n    width: 100%;\n  }\n  .member-count {\n    cursor: pointer;\n  }\n  .chatroom-header {\n    padding-top : v-bind(\"$getStyles.chatroomHeaderPaddingTop\");\n    padding-bottom : v-bind(\"$getStyles.chatroomHeaderPaddingBottom\");\n    padding-left : v-bind(\"$getStyles.chatroomHeaderPaddingLeft\");\n    padding-right : v-bind(\"$getStyles.chatroomHeaderPaddingRight\");\n    border-bottom: v-bind(\"$getStyles.chatroomHeaderBorderBottomSize\") solid v-bind(\"$getStyles.chatroomHeaderBorderBottomColor\") !important;\n  }\n",".chatroom {\n  width: 100%;\n}\n\n.member-count {\n  cursor: pointer;\n}\n\n.chatroom-header {\n  padding-top: v-bind(\"$getStyles.chatroomHeaderPaddingTop\");\n  padding-bottom: v-bind(\"$getStyles.chatroomHeaderPaddingBottom\");\n  padding-left: v-bind(\"$getStyles.chatroomHeaderPaddingLeft\");\n  padding-right: v-bind(\"$getStyles.chatroomHeaderPaddingRight\");\n  border-bottom: v-bind(\"$getStyles.chatroomHeaderBorderBottomSize\") solid v-bind(\"$getStyles.chatroomHeaderBorderBottomColor\") !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
