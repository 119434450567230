<template>
  <iRow wrap="nowrap" class="header-wrapper">
    <iLink class="message-avatar" :href="authorProfile">
      <iImageContainer
        :alt="userData.first_name"
        :height="36"
        :src="avatar"
        :width="36"
        class="user-avatar"
        object-fit="cover"
        shape="rectangle"
      />
    </iLink>
    <iColumn gap="none">
      <iRow
        class="message-header"
        gap="none"
        vertical-align="middle"
        width="hug"
      >
        <iLink :href="authorProfile">
          <iText class="user-name" font-weight="dark">
            {{ username }}
          </iText>
        </iLink>
        <iSpace :width="12" />
        <iText
          v-if="showTimestampCreated"
          v-tooltip="formattedTimestamp"
          class="timestamp"
          font-size="small"
          variant="subtle"
        >
          {{ timeAgo(date) }} ago
        </iText>
      </iRow>
      <ConversationCollaboratorMenu
        v-if="isModerator"
        :is-author="isAuthor"
        :is-moderator="isModerator"
        :moderation-enabled="moderationEnabled"
      />
      <slot name="content" />
    </iColumn>
  </iRow>
</template>

<script>
import dayjs from "dayjs";
import { mapState, mapActions } from "pinia";
import { useConversationStore } from "%/stores/conversation-store";
import { getRelativeTimeString } from "@bloglovin/vue-component-library";
import ConversationCollaboratorMenu from "%/components/community/chatrooms/slack-style/ConversationCollaboratorMenu";
import { DEFAULT_USER_AVATAR } from "%/constants/user-constants";
import { useGeneralStore } from "%/stores/general-store";
import { timeAgo } from "~/helpers/publication-helper";

export default {
  name: "ConversationHeader",
  components: {
    ConversationCollaboratorMenu,
  },
  props: {
    date: {
      type: Number,
      required: false,
      default: 0,
    },
    authorName: {
      type: String,
      required: true,
    },
    authorAvatar: {
      type: String,
      required: false,
      default: DEFAULT_USER_AVATAR,
    },
    dateEdited: {
      type: Number,
      required: false,
      default: Date.now(),
    },
    authorId: {
      type: Number,
      required: true,
    },
    authorProfile: {
      type: String,
      required: false,
      default: "#",
    },
    moderationEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    isModerator: {
      type: Boolean,
      required: false,
      default: false,
    },
    showTimestampCreated: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(useGeneralStore, ["userData", "userId"]),
    relativeDateCreated() {
      let days = Number(getRelativeTimeString(this.date).split("days ago", 1)[0]);
      if (days > 7) {
        return dayjs.unix(this.date).format("DD MMMM YYYY");
      }
      return getRelativeTimeString(this.date);
    },
    formattedTimestamp() {
      return dayjs.unix(this.date).format("DD MMMM YYYY  HH:mm");
    },
    isAuthor() {
      return this.userId == this.authorId;
    },
    avatar() {
      return (this.isAuthor ? this.userData.avatar_url : this.authorAvatar) || DEFAULT_USER_AVATAR;
    },
    username() {
      return this.isAuthor ? this.userData.first_name : this.authorName;
    },
  },
  methods: {
    timeAgo,
    ...mapActions(useConversationStore, {
      reportUser: "reportUser",
      blockUser: "blockUser",
    }),
    showMenuOptions() {
      this.showMenuOptions = false;
    },
    report(reason) {
      this.isShowingReportingOptions = false;
      this.reportUser(this.authorId, reason);
    },
    blockUser() {
      if (!this.isModerator) {
        return;
      }
      this.blockUser(this.authorId);
    },
  },
  styleGuide: () => ({
    messageContainerBackground: { "color.background": "light" },
    borderRadius: { "size.borderRadius": "large" },
    actionBackground: { "color.background": "light" },
    actionBorderColor: { "color.border": "dark" },
    actionPadding: { "size.spacing": "small" },
    containerPadding: { "size.spacing": "small" },
    avatarOffset: { "size.spacing": "standard" },
    timestampColor: { "color.font": "subtle" },
  }),
};
</script>

<style lang="scss" scoped>
:deep(.user-avatar img) {
  border-radius: v-bind("$getStyles.borderRadius");
}

.header-wrapper {
  padding-left: v-bind("$getStyles.avatarOffset");
}

.message-avatar {
  position: relative;
  top: 4px;
}
.timestamp, .timestamp:hover {
  color: v-bind("$getStyles.timestampColor");
}
</style>
