<template>
  <iRow
    v-if="isChannelUser"
    width="fill"
    align="between"
    class="manage-users"
  >
    <iButton size="small" @click="openAddUserModal">
      Add User
    </iButton>
  </iRow>
  <iColumn gap="small">
    <SmartFeed
      :pinia-store="useChannelUserFeedStore"
      default-smart-feed-layout="list"
      default-entity-layout="cardSmall"
    />
  </iColumn>
  <iModal
    :visible="showAddUserModal"
    :max-width="600"
    :show-primary-action-button="false"
    secondary-action-text="close"
    title="Invite Users to Channel"
    @close="closeAddUserModal"
    @click:secondary="closeAddUserModal"
  >
    <iColumn width="fill">
      <template v-if="publicationUsersNotInChannel.length">
        <iRow
          v-for="user in publicationUsersNotInChannel"
          :key="user.userId"
          width="fill"
          align="between"
          vertical-align="middle"
        >
          <iLink :href="userProfileHref(user.userId)">
            <iRow width="hug" vertical-align="middle">
              <AvatarImage :src="user.avatarUrl" size="35px" />
              <iText> {{ username(user) }}</iText>
            </iRow>
          </iLink>
          <iButton @click="inviteToChannel(user.userId)">
            Invite
          </iButton>
        </iRow>
      </template>
      <iText v-else>
        There are no users to add to this channel
      </iText>
    </iColumn>
  </iModal>
</template>

<script>
import { mapActions, mapState } from "pinia";
import SmartFeed from "SmartFeed/components/SmartFeed.vue";
import { useFanPublicationStore } from "%/stores/fan-publication-store";
import { useGeneralStore } from "%/stores/general-store";
import { useChannelUserFeedStore } from "SmartFeed/stores/feed/channel-user-feed-store";
import { useChannelPublicationUserFeedStore } from "SmartFeed/stores/feed/channel-publication-user-feed-store";
import { usePublicationUsersEntityStore } from "SmartFeed/stores/entity/publication-users-entity-store";
import { useChannelUserEntityStore } from "SmartFeed/stores/entity/channel-user-entity-store";
import { useChannelStore } from "%/stores/channel-store";
import AvatarImage from "%/components/AvatarImage.vue";

export default {
  name: "ManageUsers",
  components: { SmartFeed, AvatarImage },
  data() {
    return {
      searchQuery: "",
      activePublicationUsers: [],
      loadingSearchActivePublicationUsers: false,
      showAddUserModal: false,
    };
  },
  computed: {
    ...mapState(useFanPublicationStore, ["publicationId"]),
    ...mapState(useChannelStore, ["isChannelUser"]),
    ...mapState(useGeneralStore, ["fanUrl"]),
    ...mapState(usePublicationUsersEntityStore, { publicationUserEntities: "entities" }),
    ...mapState(useChannelPublicationUserFeedStore, { publicationUsersHasMore: "hasMore", publicationUserFeed: "feed" }),
    ...mapState(useChannelUserEntityStore, { channelUserEntities: "entities" }),
    ...mapState(useChannelUserFeedStore, { channelUsersHasMore: "hasMore", channelUserFeed: "feed" }),
    publicationUsers() {
      const entities = {};
      this.publicationUserFeed.forEach(publicationUser => {
        let userIds = Object.keys(this.publicationUserEntities);
        if (userIds.includes(publicationUser.id.toString())) {
          entities[publicationUser.id] = this.publicationUserEntities[publicationUser.id];
        }
      });
      return Object.values(entities);
    },
    channelUsers() {
      const entities = {};
      this.channelUserFeed.forEach(channelUser => {
        let userIds = Object.keys(this.channelUserEntities);
        if (userIds.includes(channelUser.id.toString())) {
          entities[channelUser.id] = this.channelUserEntities[channelUser.id];
        }
      });
      return Object.values(entities);
    },
    publicationUsersNotInChannel() {
      return this.publicationUsers.filter(publicationUser => {
        return !this.channelUsers.some(channelUser => {
          return channelUser.userId === publicationUser.userId;
        });
      });
    },
  },
  mounted() {
    useChannelPublicationUserFeedStore().getSmartFeed();
  },
  methods: {
    ...mapActions(useChannelStore, ["inviteUserToChannel"]),
    useChannelUserFeedStore,
    userProfileHref(userId) {
      return `${this.fanUrl}/user-profile/${userId}`;
    },
    openAddUserModal() {
      this.showAddUserModal = true;
    },
    closeAddUserModal() {
      this.showAddUserModal = false;
    },
    inviteToChannel(userId) {
      this.inviteUserToChannel(userId);
    },
    username(user) {
      return `${user.firstName} ${user.lastName}`;
    },
  },
  styleGuide: () => ({
    paddingTop: { "size.spacing": "standard" },
    paddingBottom: { "size.spacing": "standard" },
    paddingLeft: { "size.spacing": "standard" },
    paddingRight: { "size.spacing": "standard" },
  }),
};
</script>
<style lang="scss" scoped>
.manage-users{
  padding-top: v-bind("$getStyles.paddingTop");
  padding-bottom: v-bind("$getStyles.paddingBottom");
  padding-left: v-bind("$getStyles.paddingLeft");
  padding-right: v-bind("$getStyles.paddingRight");
}
</style>
