<template>
  <iTextInput
    :model-value="modelValue"
    :label="label"
    size="small"
    @update:model-value="updateFilter"
  />
</template>

<script>
export default {
  name: "SmartFeedFilterTextInput",
  props: {
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      type: [Number, String, Object],
      required: true,
    },
  },
  emits: ["update:modelValue", "change"],
  methods: {
    updateFilter(value) {
      this.$emit("update:modelValue", value);
      if (value !== this.modelValue) {
        this.$emit("change", value);
      }
    },
  },
};
</script>
